export const environment = {
  name: 'prod',
  version: 'v1-232-0',
  production: true,
  sentry: {
    dsn: 'https://8e8579440b241f7beca527816cf959a3@o4506207716114432.ingest.sentry.io/4506581323153408',
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 1,
    showDialog: false,
  },
  firebase: {
    region: 'europe-west1',
    apiKey: 'AIzaSyDrVsDjetv4CAtOGesgTFV5C7UZXL0SzM8',
    authDomain: 'admin.freddy.ovh',
    databaseURL:
      'https://freddy-prod-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'freddy-prod',
    storageBucket: 'freddy-prod.appspot.com',
    messagingSenderId: '726871418891',
    appId: '1:726871418891:web:8b2b60d2d2c4c3db5b3f0e',
    measurementId: 'G-2VDQM54B55',
    recaptcha: '6Ld7jxYqAAAAACuU6wfebxko4byvpqANYJ9Vx9jL',
  },
  mapbox: {
    token:
      'pk.eyJ1IjoiYm9rem9yIiwiYSI6ImNseXloZ3FobzI4djcycXNoYWdhajA0dngifQ.PgA8mAMfTXoI4zYrNVXrQw',
    geocoder: {
      countriesActivated: 'fr,nl,be,de',
    },
  },
  typesense: {
    apiKey: 'freddy-admin',
    host: 'search.freddy.ovh',
    port: 443,
    protocol: 'https',
    scenariosCollectionName: 'scenarios-prod',
    gamesCollectionName: 'games-prod',
  },
  game: {
    defaultRadius: 15,
    defaultPoints: 10,
  },
};
